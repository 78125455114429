import React from 'react'

const Header = () => {
  return (
    <div>
      <h2 className='site-title'>
        Chuck D'Antonio
      </h2> 
    </div>
  )
}

export default Header
